type TConfig = {
  common: string[];
  feature: string;
};

export const profileTranslationConfig: TConfig = {
  common: [],
  feature: 'Feature.Profile'
};

export default {
  profileTranslationConfig
};
